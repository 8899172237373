import axios from 'axios';

export const apiContactForm = data => {
    return axios({
        url: 'https://api.jiy-apps.com/contact/v1/',
        method: 'post',
        params: data,
    })
        .then(response => response)
        .catch(error => error);
};
