import React, {useReducer} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import {apiContactForm} from './api/contact';

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .required('Please enter your name'),
    email: Yup.string()
        .email('You have entered an invalid e-mail address')
        .required('Please enter your e-mail address'),
    subject: Yup.string()
        .required('Please enter a subject of your message'),
    message: Yup.string()
        .required('Please enter your message'),
});

function init(initialState) {
    return {
        sending: initialState.sending,
        sent: initialState.sent,
        error: initialState.error,
    };
}

function reducer(state, {type}) {
    switch (type) {
        case 'SEND_START':
            return {
                ...state,
                ...{
                    sending: true,
                    sent: false,
                    error: false,
                },
            };
        case 'SEND_SUCCESS':
            return {
                ...state,
                ...{
                    sending: false,
                    sent: true,
                    error: false,
                },
            };
        case 'SEND_FAILURE':
            return {
                ...state,
                ...{
                    sending: false,
                    sent: true,
                    error: true,
                },
            };
        case 'RESET':
            return {
                ...state,
                ...{
                    sending: false,
                    sent: false,
                    error: false,
                },
            };
        default:
            throw new Error();
    }
}

function App() {
    const initialState = {
        sending: false,
        sent: false,
        error: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState, init);

    const handleSubmitMessage = (values, {setSubmitting}) => {
        dispatch({type: 'SEND_START'});

        const data = {
            name: values.name,
            email: values.email,
        };

        apiContactForm({
            uid: 'No uid',
            subj_key: `[jiy-apps.com ${values.subject}]`,
            msg: values.message,
            data: JSON.stringify(data, null, '\t'),
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch({type: 'SEND_SUCCESS'});
                } else {
                    dispatch({type: 'SEND_FAILURE'});
                }
            })
            .catch(() => {
                dispatch({type: 'SEND_FAILURE'});
            });

        setSubmitting(false);
    };

    return (
        <div className="App">
            {!state.sent &&
            <p>Write us something. We are waiting for your message</p>
            }
            {state.sent ? (
                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                    {state.error ? (
                        <div className="u-text-center">
                            <div className="w-vertical-gap-xs-xs">
                                <i
                                    className="material-icons c-ico c-ico--xl c-ico--primary u-text-danger"
                                    data-ico="error"/>
                            </div>
                            <div className="w-vertical-gap-xs-lg">
                                <div className="c-h4">Your message has not been sent.</div>
                            </div>
                            <button
                                className="gui-btn gui-btn--md gui-btn--primary"
                                type="button"
                                onClick={() => dispatch({type: 'RESET'})}>
                                Try again
                            </button>
                        </div>
                    ) : (
                        <div className="u-text-center">
                            <div className="w-vertical-gap-xs-xs">
                                <i
                                    className="material-icons c-ico c-ico--xl c-ico--primary u-text-success"
                                    data-ico="check_circle"/>
                            </div>
                            <div className="w-vertical-gap-xs-xs">
                                <div className="c-h4">Your message has been successfully sent.</div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <Formik
                        initialValues={{name: '', email: '', subject: '', message: ''}}
                        validationSchema={ContactSchema}
                        onSubmit={handleSubmitMessage}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                                    <div className="grid">
                                        <div className="grid__cell-xs-12 grid__cell-md-8">
                                            <div className={cn([
                                                'gui-form-group',
                                                touched.name && errors.name && 'has-error',
                                            ])}>
                                                <div className="w-vertical-gap-xs-sm">
                                                    <label className="u-text-bold" htmlFor="name">Your name</label>
                                                </div>
                                                <input
                                                    className="gui-text-field"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                                {touched.name && errors.name &&
                                                <div className="gui-field-msg">{errors.name}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                                    <div className="grid">
                                        <div className="grid__cell-xs-12 grid__cell-md-8">
                                            <div className={cn([
                                                'gui-form-group',
                                                touched.email && errors.email && 'has-error',
                                            ])}>
                                                <div className="w-vertical-gap-xs-sm">
                                                    <label className="u-text-bold" htmlFor="email">Your email</label>
                                                </div>
                                                <input
                                                    className="gui-text-field"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {touched.email && errors.email &&
                                                <div className="gui-field-msg">{errors.email}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                                    <div className="grid">
                                        <div className="grid__cell-xs-12 grid__cell-md-8">
                                            <div className={cn([
                                                'gui-form-group',
                                                touched.subject && errors.subject && 'has-error',
                                            ])}>
                                                <div className="w-vertical-gap-xs-sm">
                                                    <label className="u-text-bold" htmlFor="subject">Subject</label>
                                                </div>
                                                <input
                                                    className="gui-text-field"
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.subject}
                                                />
                                                {touched.subject && errors.subject &&
                                                <div className="gui-field-msg">{errors.subject}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                                    <div className={cn([
                                        'gui-form-group',
                                        touched.message && errors.message && 'has-error',
                                    ])}>
                                        <div className="w-vertical-gap-xs-sm">
                                            <label className="u-text-bold" htmlFor="message">Your message</label>
                                        </div>
                                        <textarea
                                            className="gui-text-field"
                                            rows="7"
                                            id="message"
                                            name="message"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.message}
                                        />
                                        {touched.message && errors.message &&
                                        <div className="gui-field-msg">{errors.message}</div>
                                        }
                                    </div>
                                </div>
                                <div className="w-vertical-gap-xs-md w-vertical-gap-sm-xl">
                                    <button
                                        className="gui-btn gui-btn--md gui-btn--primary"
                                        type="submit"
                                        disabled={isSubmitting || state.sending}>
                                        {state.sending ? 'Sending...' : 'Send message'}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
}

export default App;
